.ContainerAccept {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.Back {
  display: flex;
  position: absolute;
  left: 25px;
  outline: none;
  cursor: pointer;
  width: 200px;
}

.Back > p {
  margin: 0 0 0 6px;
  font-family: ModernEra;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #0752f9;
}

.CenterContainer {
  margin-top: 34px;
  display: flex;
  flex-direction: column;
  padding: 28px;
  width: 100%;
  max-width: 260px;
  border-radius: 10px;
  border: solid 1.3px #003fff;
  background-color: #ffffff;
  outline: none;
}

.SubmitButton {
  border-radius: 10px;
  box-shadow: 4px 4px 4px 0 rgba(0, 63, 255, 0.24);
  background-color: #0752f9;
  padding: 16px 70px;
  margin-top: 53px;
  font-family: ModernEra;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  outline: none;
  cursor: pointer;
}

.InfoDate {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.Item {
  display: flex;
}

.ItemSecond {
  display: flex;
  margin-top: 26px;
}

.AddressContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.Dates {
  text-transform: capitalize;
}

.Center, .Dates {
  margin: 0;
  font-family: ModernEraBold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #003fff;
}

.Address {
  margin: 0;
  font-family: ModernEra;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.Icon {
  height: 40px;
  width: 40px;
}

.ContainerLoader {
  margin-top: 50px;
}

.Error {
	color: red;
	margin: 20px 10px;
  font-family: "ModernEra";
  text-align: center;
}

.TitleName {
  display: none;
  margin: 0;
  font-family: QuincyCF;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

.Description {
  margin: 26px 0 0;
  width: 100%;
  font-family: 'Inter Regular';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0125em;
  text-align: center;
  color: #757575;
}

@media(max-width: 350px){
  .CenterContainer {
    padding: 28px 10px;
  }
}

@media(min-width: 700px){
  .CenterContainer {
    max-width: 408px;
    padding: 40px 50px;
  }

  .AddressContainer {
    display: flex;
    flex-direction: column;
    margin-left: 66px;
  }

  .InfoDate {
    display: flex;
    flex-direction: column;
    margin-left: 66px;
  }

  .ContainerAccept {
    margin-bottom: 83px;
  }

  .TitleName {
    display: flex;
  }

}

@media(max-width: 699px){
  .SubmitButton {
    width: 100%;
    padding: 16px 20px;
    max-width: 240px;
  }
}