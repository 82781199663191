.CounterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px 0;
  border-radius: 10px;
  background-color: #e2e2e2;
  font-family: 'ModernEra';
  font-size: 13px;
  margin-top: 40px;
  color: #4971a8;
  max-width: 316px;
  width: 100%;
}

.CounterContainer > p{
  margin: 0 10px;
  font-family: ModernEra;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

.TimeExpired {
  border-radius: 10px;
  background-color: #fcf6b5;
}

.Counter {
  margin-top: 16px !important;
}

@media(max-width: 350px){
  .CounterContainer {
    max-width: 280px;
  }
}

@media(min-width: 700px){
  .CounterContainer {
    max-width: 408px;
    padding: 15px 50px;
  }
}