.Container {
  display: flex;
  flex-direction: column;
  margin: 14px 17px;
}

.Header {
  display: flex;
  align-items: flex-start;
}

.ItemStart {
  margin-right: 6.5px;
}

.ContainerName {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Images {
  width: 66px;
  height: 66px;
  margin-right: 17px;
}

.Name {
  margin: 0;
  font-family: ModernEraBold;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.Title {
  margin: 14px 0 0;
  font-family: ModernEraBold;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.Description {
  margin: 16px 0 0;
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.OkBlue {
}