.FooterContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #000000;
  margin-top: 29.8px;
}

.Left, .Right {
  display: flex;
  align-items: center;
  margin: 34px 32px;
  text-decoration:none;
}

.Icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.Mobile {
  margin: 0;
  font-family: HelveticaNeue;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.Facebook {
  width: 11.8px;
  height: 22px;
  margin-right: 14px;
}

.Instagram {
  width: 19.3px;
  height: 19.3px;
  margin-right: 14px;
}

.YouTube {
  width: 23.5px;
  height: 16.5px
}


@media (max-width: 350px) {
  .Left, .Right {
    margin: 34px 21px;
  }
}


@media(min-width: 700px){
  .Facebook, .Instagram {
    margin-right: 48px;
  }
}