.ErrorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #acbbbf;
  min-height: -webkit-fill-available;
}

.Logo {
  display: flex;
  margin: 83px 0 0;
  color: #ffffff;
}

.Error404 {
  font-family: 'RobotoBold';
  font-size: 49px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 91px;
}

.Subtitle {
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 15px;
}

.NoFound {
  font-family: 'ModernEra';
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.49);
  margin-top: 5px;
}

.MoonsFooter {
  width: 100%;
  padding-top: 90px;
  margin-top: auto;
}

.ImgMoonsFooter {
  width: 100%;
}

@media (max-width: 350px) {
  .Subtitle, .NoFound {
    font-size: 17px;
  }
}

@media (min-width:700px) {

  .Logo {
    margin: 190px 0 0;
  }


  .MoonsFooter {
    padding-top: 100px;
  }
}